<template>
  <div class="DashboardView">
    <DashboardTopBar
      class="header"
      :can-show-upgrade="$route.meta.canShowUpgrade" />
    <!-- <div class="body"> -->
    <router-view
      :key="$route.path"
      class="body" />
    <!-- </div> -->
  </div>
</template>

<script>
import DashboardTopBar from '../components/DashboardTopBar';

export default {
    components: { DashboardTopBar }
};
</script>

<style lang="scss" scoped>
.DashboardView {
    background: linear-gradient(180deg, #0a1335 0%, #1e1456 93.87%);
    color: white;
}
.header {
}

.body {
    // padding: 2em 2em;
    // overflow: auto;
    height: calc(100% - #{$topBarHeight});
}
</style>
